import React from "react";
import { Grid } from '@material-ui/core';
import styled from "styled-components"
import { Link } from 'gatsby';
import logo from '../images/logos/sprinklesomethinglogo.png';

const A = styled.a`
  margin: 4px 0;
  text-decoration: none;
  color: black;
  @media screen and (max-width: 959px) {
    padding: 0 5px;
  }
`
const Logo = styled.img`
  height: 50px;
  width: 75px;
`

const Footer = () => {
  return (
    <footer>
      <Grid container className="footer-border"></Grid>
      <Grid className="footer">
        <Grid container item xs={12}>
          <Grid container item xs={12} justify="center">
            <Link to='/' aria-label="Logo"><Logo src={logo} ></Logo></Link>
          </Grid>
          <Grid container item xs={12} justify="center"><p>Connect with us to stay updated!</p></Grid>
          <Grid container item xs={12} justify="center">
              <A href="https://etsy.me/3u0WHhS" target="_blank" rel="noreferrer">
                Etsy Shop
              </A>
            </Grid>
            <Grid container item xs={12} justify="center">
              <A href="https://www.instagram.com/sprinklesomething" target="_blank" rel="noreferrer">
                Instagram
              </A>
            </Grid>
            <Grid container item xs={12} justify="center">
            <A href="https://www.facebook.com/sprinklesomething" target="_blank" rel="noreferrer">
                Facebook
              </A>
            </Grid>
            <Grid container item xs={12} justify="center">
              {/* <A href="mailto:sprinklesomethings@gmail.com">
                Email
              </A> */}
            </Grid>
          <Grid container item xs={12} justify="center">&copy; 2021 KimCL. All rights reserved.</Grid>
          </Grid>
      </Grid>
      <Grid container className="footer-border"></Grid>
    </footer>
  )
}
export default Footer;
