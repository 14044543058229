import React from "react";
import { Link } from 'gatsby';
import logo from '../images/logos/sprinklesomethinglogo.png';
import { AppBar, Grid, makeStyles, Divider } from '@material-ui/core';
import styled from "styled-components";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const Logo = styled.img`
  height: 150px;
  width: 350px;
  border-radius: 40%;
`

const Nav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const useStyles = makeStyles((theme) => ({
  header: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#FAEEFD',
    padding: '6px',
    '& hr': {
      margin: theme.spacing(0, 1.5),
    },
    '& a': {
      textDecoration: 'none',
      color: 'black'
    }
  },
  headerBorder: {
    backgroundColor: '#110B11',
    padding: '13px'
  },
  appbar: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F7F2E7',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0 5px 8px rgba(43,43,43,.1)'
  }
}));
const A = styled.a`
  padding: 0 7px;
  text-decoration: none;
  color: black;
  @media screen and (max-width: 959px) {
    padding: 0 5px;
  }
`

const Header = (props) => {
  const classes = useStyles();
  return (
    <main>
      <title>{`Sprinkle Something | ${props.title}`}</title>
      <AppBar position="static" className={classes.appbar}>
        <Link to='/' aria-label="Logo"><Logo src={logo} ></Logo></Link>
        <Grid container item xs={12} justify="flex-end">
            <A href="https://www.facebook.com/sprinklesomething" target="_blank" rel="noreferrer">
              <FacebookIcon fontSize="medium" />
            </A>
            <A href="https://www.instagram.com/sprinklesomething" target="_blank" rel="noreferrer">
              <InstagramIcon fontSize="medium" />
            </A>
            {/* <A href="mailto:sprinklesomethings@gmail.com">
              <EmailIcon fontSize="medium" />
            </A> */}
          </Grid>
      </AppBar>
      <Nav>
        <Grid container className={classes.header} justify="center">
          <Link to="/">Home</Link>
          <Divider orientation="vertical" />
          <Link to="/menu">Menu</Link>
          <Divider orientation="vertical" />
          <Link to="/cakes">Cakes</Link>
          <Divider orientation="vertical" />
          <Link to="/cookies">Sugar Cookies</Link>
          <Divider orientation="vertical" />
          <Link to="/treats">Treats</Link>
          <Divider orientation="vertical" />
          <Link to="/pooja_sweets">Pooja Sweets</Link>
          <Divider orientation="vertical" />
          <Link to="/faq">FAQs</Link>
        </Grid>
      </Nav>
</main>
  )
}
export default Header
